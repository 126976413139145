import appReducer from './app.slice'
import userReducer from './user.slice'
import authenticationReducer from './authentication.slice'
import {REDUX_SLICES} from "../constant";

export const getReduxState = (stateName, selector) => {
    switch (stateName) {
        case REDUX_SLICES.APP:
            return selector((state) => state[REDUX_SLICES.APP]);
        case REDUX_SLICES.AUTHENTICATION:
            return selector((state) => state[REDUX_SLICES.AUTHENTICATION]);
        case REDUX_SLICES.USER:
            return selector((state) => state[REDUX_SLICES.USER]);
    }
}
export  {appReducer, userReducer, authenticationReducer}