import React, {useEffect} from 'react'
import {GridRow} from 'semantic-ui-react';
import Layout from '../Template/DefaultLayout';
import {Box, Typography} from '@material-ui/core'
import LogoImage from '../../assets/icons/icon.png'
import userService from '../../services/user.service'
import {saveAs} from 'file-saver';
import {useSelector} from "react-redux";
import {getReduxState} from "../../slices";
import {REDUX_SLICES} from "../../constant";

const DownloadDataContainer = (props) => {
    const {isLoading}= getReduxState(REDUX_SLICES.APP, useSelector)
    useEffect(()=>{
        const queryString = props.location.search.substring(1);
        const qsParams = new URLSearchParams(queryString);
        const token = qsParams.get('token');
        const id = qsParams.get('id');
        if (!token) {
            return props.history.push('/')
        }
        const params = {
            id,
            token
        }
        userService.downloadData(params).then(res=>{
            const fileName = 'my-data';
            // const blob = new Blob([res.data], { type : 'application/zip' });
            const file = new File([res], fileName + '.zip', { type: 'application/zip' });
            saveAs(file);
        }).catch(err=>{
            console.log(err)
        })
    },[])
    return  (
    <Layout>
        <GridRow>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={700} margin="auto" minHeight={500}>
                <img style={{width:"100px", marginBottom:"50px"}} src={LogoImage}></img>
                <Typography variant="h4" align="center"> Your download should begin shortly.</Typography>
            </Box>
        </GridRow>
    </Layout>
    )
}

export default DownloadDataContainer