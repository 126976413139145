import React from 'react';
import {useSelector} from 'react-redux';
import {Dimmer, Loader} from 'semantic-ui-react';
import {REDUX_SLICES} from "../constant";
import {getReduxState} from "../slices";

export default function AppLoader  () {
    const {isLoading}= getReduxState(REDUX_SLICES.APP, useSelector);
    return <Dimmer page active={isLoading} inverted>
        <Loader />
    </Dimmer>
}


