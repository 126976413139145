import httpService from './http.service';
import httpClient from 'axios'
import config from '../environment/config';

const baseUrl = `${config.api_url}/files`;
class FileService {
    async uploadFile (container, file, type) {
        const url = baseUrl + `/${container}/upload?fileName=${file.name}`;
        const res = await httpService.get(url);
        await httpClient.put(res.url, file, {
            headers: {
                Accept: 'application/json',
                'Content-Type': file.type
            }
        })
        return res.url.split('?X-Amz-Algorithm')[0];
    }


    async uploadMultipleFile (container, files, type) {
        const promises = [];
        for (const file of files) {
            // let formData = new FormData();
            // formData.append(type, file, file.name);
            if (file) {
                promises.push(this.uploadFile(container, file));
            }else {
                promises.push(new Promise(((resolve, reject) => {
                    resolve([])
                })))
            }
        }
        const res = await Promise.allSettled(promises)
        return res.map(r => r.value);
    }

    async getPreSignedUrlDownload(container, fileName) {
        const path = baseUrl +`/${container}/download/${fileName}`;
        return httpService.get(path);
    }

    removeAwsPreSignedUrl(url) {
        console.log('Presign', url);
        return url.includes('?X-Amz-Algorithm') ? url.split('?X-Amz-Algorithm')[0]: url;
    }
}

export default new FileService()