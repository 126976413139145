import httpService from './http.service';
import config from '../environment/config';

class CharityCategoriesService {
    base_url = `${config.api_url}/charitycategories`;
    async get() {
        console.log('Get categories');
        return httpService.get(this.base_url)
    }
}
export default new CharityCategoriesService()
