

export const verifyMsg = {
    orther: 'An error occurred while verifying the account',
    activated: `Your account has been verified`,
    success: 'Verify acount successfully'
}
export const verifyCode = {
    not_reset_pwd: 'ACTIVATED_NOT_RESET_PWD',
    user_not_found: 'USER_NOT_FOUND',
    activated: 'ACTIVATED'
}
export const getProfileMsg = {
    GET_PROFILE_ERROR: 'Fail! Something went wrong while getting profile page'
}
export const editProfileMsg = {
    EMAIL_EXISTED: 'This email has been used already!',
    GENERAL_ERROR: 'Failed. Something went wrong while updating',
    REQUIRED_UNIQUE: 'REQUIRED_UNIQUE'
}



export const userAccountConstant = {
    GET_USER: 'GET_USER',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PASSWORD_SUCCESS : 'UPDATE_PASSWORD_SUCCESS'
}

export const localStorageConstant = {
    USER: 'user'
}

export const PAGE_LIMIT = 20;

export const url_logo = '/public/logo.png'
export const KYC_STATUSES = {
    DRAFT: "draft",
    BEING_REVIEWED: "being_reviewed",
    SUBMITTED_TO_PSP: "submitted_to_psp",
    APPROVED: "approved",
    REJECTED: "rejected",
    REFUSED: "REFUSED"
}
export const KYC_PROOF_TYPES = {
    IDENTITY_PROOF: "IDENTITY_PROOF",
    ASSOCIATION_ARTICLES_PROOF: "ARTICLES_OF_ASSOCIATION",
    REGISTRATION_PROOF: "REGISTRATION_PROOF"
}

export const REDUX_SLICES = {
    APP: 'app',
    USER: 'user',
    AUTHENTICATION: 'auth'
}


export const MILLISECOND_OF_1_DAY = 1 * 3600 * 24 * 1000;
export const FILE_CONTAINERS = {
    KYC : "Kyc",
    CHALLENGE : "Challenge",
    CHARITY_ORG : "Charityorg",
    CIRCLE : "Circle",
    PMESSAGE : "Pmessage",
    POST : "Post",
    TAKER : "Taker",
    USER : "User",
    REPORT : "Report",
    SPLASH_SCREEN : "Splash-screen",
    RECEIPT : "Receipt",
    AVATAR : "Avatar"
}

export const MANGO_PAY_TERM_CONDITIONS_URL = "https://www.mangopay.com/terms/MANGOPAY_Terms-FR.pdf"
