import {useLocation} from 'react-router-dom';

export default function useQuery() {
    const query = new URLSearchParams(useLocation().search);
    const queryParams = {}
    for (const key of query.keys()) {
        queryParams[key] = query.get(key);
    }
    return queryParams
}
